import { http } from '../utils/http';

const publicPath = '/api/driver-center/driver/mine/truck';

export const getTruckByIdApi = async params => {
  // 查询车队
  return await http.post(`${publicPath}/getTruckById`, params, false);
};

export const deleteTruckByDriverApi = async params => {
  //删除车辆
  return await http.post(`${publicPath}/deletedTruck`, params, false);
};
