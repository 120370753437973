<template>
  <div class="warning-wrap pa-16">
    <div class="warning-message pa-16">
      <div class="font-14 fw-700 mb-12 d-flex lh-20">
        <Icon name="waiting" svgClass="mr-4" />您提交的认证信息正在审核中...
      </div>
      <div class="button-warning mt-16 fw-700 lh-38 font-16" @click="handleJumpEdit">
        重新认证
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import { Dialog } from 'vant';
import { apiWithdrawTruck } from '@api/mine';
export default defineComponent({
  props: {
    truckRef: {
      type: Object,
      default: {}
    }
  },
  setup(_, ctx) {
    const handleJumpEdit = async () => {
      const { $router } = ctx.root;
      Dialog.confirm({
        message: '您提交的信息正在审核中，重新认证后，平台将取消审核，是否继续操作!'
      })
        .then(async () => {
          await apiWithdrawTruck({ truckId: $router.currentRoute.query.id }); // 重新认证之前先进行撤回操作
          $router.push(`/mine/car/addCar?truckId=${$router.currentRoute.query.id}&isEdit=false`);
        })
        .catch(err => {
          console.log(err);
        });
    };
    return {
      handleJumpEdit
    };
  }
});
</script>
<style lang="less">
.warning-wrap {
  background-image: linear-gradient(to bottom, transparent 50%, white 51%);
  width: 100vw;
  min-height: 1.67rem;
  margin-top: 0.5rem;
  .warning-message {
    border-radius: 4px;
    color: white;
    box-shadow: 0px 4px 10px 0px rgba(200, 207, 216, 0.5);
    background: rgba(252, 148, 18, 0.8);
    .button-warning {
      color: rgba(252, 148, 18, 1);
      background: #ffffff;
      width: 100%;
      border-radius: 4px;
      height: 0.38rem;
      text-align: center;
    }
  }
}
</style>
