<template>
  <div class="error-wrap pa-16">
    <div class="error-message pa-16">
      <div class="font-14 fw-700 mb-12 d-flex lh-20"><Icon name="sc" svgClass="mr-4" />您提交的信息认证失败</div>
      <div class="font-12" v-if="truckRef.refuseReason">
        {{ truckRef.refuseReason.map(item => item.remarks).join('、') }}
      </div>
      <div class="button-reset mt-16 fw-700 lh-38 font-16" @click="handleJumpEdit">
        重新认证
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  props: {
    truckRef: {
      type: Object,
      default: {}
    }
  },
  setup(_, ctx) {
    const handleJumpEdit = async () => {
      const { $router } = ctx.root;
      $router.push(`/mine/car/addCar?truckId=${$router.currentRoute.query.id}&isEdit=true`);
    };
    return {
      handleJumpEdit
    };
  }
});
</script>
<style lang="less">
.error-wrap {
  background-image: linear-gradient(to bottom, transparent 50%, white 51%);
  width: 100vw;
  min-height: 1.67rem;
  margin-top: 0.5rem;
  .error-message {
    color: white;
    background: rgba(255, 96, 97, 0.8);
    border-radius: 4px;
    box-shadow: 0px 4px 10px 0px rgba(200, 207, 216, 0.5);
    .button-reset {
      color: rgba(255, 255, 255, 0.8);
      background: #ffffff;
      width: 100%;
      border-radius: 4px;
      height: 0.38rem;
      color: rgba(255, 127, 129, 1);
      text-align: center;
    }
  }
}
</style>
