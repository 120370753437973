<template>
  <Page>
    <Navbar title="车辆信息">
      <template #right>
        <span class="text_primary px-10" @click="handleRemoveFromFleet">移出车队</span>
      </template>
    </Navbar>
    <div class="page-content">
      <div v-if="truckRef.auditStatus !== 2">
        <component :is="currentComponent" :truckRef="truckRef" />
      </div>
      <van-cell-group class="py-24">
        <div class="text-main bg-white pb-16 px-16">
          <Icon name="cph"></Icon>
          <span class="font-14 pl-8">车牌号:</span>
        </div>
        <div class="px-16 pb-24">
          <div class="text_muted pb-8">行驶证</div>
          <div class="license-img-wrap w-full flex flex-row justify-space-between">
            <div class="preview-img" @click="handleImagePreview(0)">
              <van-image class="w-full h-full" fit="cover" :src="imageUrl(truckRef.drivingLicenseMainUrl)"></van-image>
              <div class="text">
                点击查看
              </div>
            </div>
          </div>
        </div>

        <van-cell title="车牌号" :value="truckRef.carLicensePlate"></van-cell>
      </van-cell-group>

      <van-cell-group class="py-24 mt-16">
        <div class="text-main bg-white pb-16 px-16">
          <Icon name="xsz"></Icon>
          <span class="font-14 pl-8">行驶证:</span>
        </div>
        <div class="px-16 pb-24">
          <div class="text_muted pb-8">行驶证</div>
          <div class="license-img-wrap w-full flex flex-row justify-space-between">
            <div class="preview-img" @click="handleImagePreview(0)">
              <van-image class="w-full h-full" fit="cover" :src="imageUrl(truckRef.drivingLicenseMainUrl)"></van-image>
              <div class="text">
                点击查看
              </div>
            </div>
          </div>
        </div>

        <van-cell title="车辆所有人" :value="truckRef.carLicensePlate"></van-cell>
        <van-cell title="车牌颜色" :value="truckRef.plateColorName"></van-cell>
        <van-cell title="车辆类型" :value="truckRef.truckTypeName"></van-cell>
        <van-cell title="使用性质" :value="truckRef.useNature"></van-cell>
        <van-cell title="车辆识别代号" :value="truckRef.identifyCode"></van-cell>
        <van-cell title="注册日期" :value="formatToDateTime(truckRef.issueDate, 'yyyy-MM-dd')"></van-cell>
        <van-cell title="发证日期" :value="formatToDateTime(truckRef.registerDate, 'yyyy-MM-dd')"></van-cell>
        <van-cell title="能源类型" :value="truckRef.energyTypeName"></van-cell>
        <van-cell title="核定载质量kg" :value="truckRef.loadWeight / 1000"></van-cell>
        <van-cell title="总质量kg" :value="truckRef.totalWeight / 1000"></van-cell>
        <van-cell title="发证机关 " :value="truckRef.issueOrganization"></van-cell>
        <van-cell title="档案编号" :value="truckRef.fileNumber"></van-cell>
        <van-cell title="挂车拍照号" :value="truckRef.trailerPlateNumber"></van-cell>
        <van-cell title="外廓长" :value="`${truckRef.outlineDimensionLength}mm`"></van-cell>
        <van-cell title="外廓宽" :value="`${truckRef.outlineDimensionWidth}mm`"></van-cell>
        <van-cell title="外廓高" :value="`${truckRef.outlineDimensionHeight}mm`"></van-cell>
      </van-cell-group>

      <van-cell-group class="py-24 mt-16">
        <div class="text-main bg-white pb-16 px-16">
          <Icon name="dlysz"></Icon>
          <span class="font-14 pl-8">道路运输证:</span>
        </div>
        <div class="px-16 pb-24">
          <div class="text_muted pb-8">道路运输证</div>
          <div class="license-img-wrap w-full flex flex-row justify-space-between">
            <div class="preview-img" @click="handleImagePreview(2)">
              <van-image
                class="w-full h-full"
                fit="cover"
                :src="imageUrl(truckRef.transportLicenseMainUrl)"
              ></van-image>
              <div class="text">
                点击查看
              </div>
            </div>
          </div>
        </div>
        <van-cell title="道路运输证号" :value="truckRef.roadTransportNumber"></van-cell>
        <van-cell title="业户名称" :value="truckRef.businessName"></van-cell>
        <van-cell title="道路运输经营许可证" :value="truckRef.roadTransportPermitNumber"></van-cell>
      </van-cell-group>
    </div>
  </Page>
</template>

<script>
import { defineComponent, ref, onBeforeMount, computed, nextTick } from '@vue/composition-api';
import { getTruckByIdApi } from '@/api/truck';
import { removeTruckFromFleetApi } from '@/api/carrier-truck';
import { getThumbnailFileTemporaryUrlListApi } from '@/api/common';
import { useRoute, useRouter } from '@/utils/compsitionHack';
import { Cell, CellGroup, Image, ImagePreview, Dialog } from 'vant';
import { formatToDateTime } from '@wlhy-web-lib/shared';
import { useUserStore } from '@/store/user';
import { carsTypeEnumMap } from '../../../mine/constants';
import { useCarrierStore } from '@/store/carrier';
import Errorcomponent from '@/components/TruckDetailStatus/errorcomponent';
import Warning from '@/components/TruckDetailStatus/warning';

export default defineComponent({
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    Errorcomponent,
    Warning
  },
  setup() {
    const truckRef = ref({});
    const { query } = useRoute();
    const imageMapRef = ref({});
    const router = useRouter();
    const currentComponent = ref();

    const userStore = useUserStore();
    const carrierStore = useCarrierStore();

    const imageUrl = computed(() => key => {
      if (imageMapRef.value[key]) {
        return imageMapRef.value[key].url;
      } else {
        return null;
      }
    });

    const imageThumbnail = key => {
      if (imageMapRef.value[key]) {
        return imageMapRef.value[key].url;
      } else {
        return null;
      }
    };

    const handleImagePreview = index => {
      ImagePreview({
        images: [
          imageThumbnail(truckRef.value.drivingLicenseMainUrl),
          imageThumbnail(truckRef.value.drivingLicenseSideUrl || ''),
          imageThumbnail(truckRef.value.transportLicenseMainUrl),
          imageThumbnail(truckRef.value.transportLicenseSideUrl || '')
        ],
        startPosition: index
      });
    };

    const handleRemoveFromFleet = () => {
      Dialog.confirm({
        title: '提示',
        message: '是否确定移除该车辆？',
        confirmButtonText: '移除'
      }).then(async () => {
        const { carrierId } = userStore.userInfo;
        const { truckId } = truckRef.value;
        try {
          await removeTruckFromFleetApi({ carrierId, truckId });
          await carrierStore.getFleetTrucksAction();
          nextTick(() => {
            router.back();
          });
        } catch (error) {
          console.log('err is ->', error);
        }
      });
    };

    onBeforeMount(async () => {
      try {
        const { data } = await getTruckByIdApi({ truckId: query.id });
        if (data) {
          truckRef.value = data;
          truckRef.value.energyTypeName = carsTypeEnumMap[data.energyType];
          const filenames = [
            data.drivingLicenseMainUrl,
            data.drivingLicenseSideUrl || '',
            data.transportLicenseMainUrl || '',
            data.transportLicenseSideUrl || ''
          ];
          const res = await getThumbnailFileTemporaryUrlListApi({ filenames, process: 'image/resize,w_200' });
          imageMapRef.value = res.data;
          currentComponent.value = data.auditStatus === 1 ? Warning : Errorcomponent;
        }
      } catch (error) {
        console.log('err is ->', error);
      }
    });
    return {
      currentComponent,
      truckRef,
      imageUrl,
      handleImagePreview,
      formatToDateTime,
      handleRemoveFromFleet
    };
  }
});
</script>
<style lang="less">
.page-content {
  min-height: 100vh;
}
</style>
